import { Ctrl } from ":mods";
import { A } from "@solidjs/router";
// import { BASE_ROUTES } from "../const";
import { Show, createSignal } from "solid-js";
import { Portal } from "solid-js/web";
import { SignupPopup } from "../components";

export function TopExtras(props: Ctrl.Nav.model.TopNavJSXItemProps) {
  const [showPopup, setShowPopup] = createSignal(false);
  const handlePopup = () => {
    console.log("popped up");
    setShowPopup(showPopup() ? false : true);
  };
  return (
    <div class="inline-flex justify-end items-center space-x-1 self-end h-full">
      <A
        href={Ctrl.Routes.CONST.BASE_ROUTES.auth}
        class="text#n-50 whitespace-nowrap !mr-5 cursor-pointer !no-underline self-center"
      >
        Log in
      </A>
      <div
        class="border-2 border-white border-solid text-0.6rem py-8px px-15px rounded-3px text#n-50 whitespace-nowrap cursor-pointer !no-underline"
        onClick={handlePopup}
      >
        <span>Join the course</span>
      </div>
      <Show when={showPopup()}>
        <Portal>
          <SignupPopup onClosePopup={handlePopup} class="top-0" />
        </Portal>
      </Show>
    </div>
  );
}
